import { api } from '@/utils';

const state = {
  all: [],
  meta: [],
  loading: false,
  initialized: false,
};

const getters = {
  courierById: state => id => state.all.find(o => o.id === id || o.proxied_id === id),
  metaById: (state, getters) => id => {
    let courier = getters.courierById(id);
    if (courier) return state.meta.find(c => c.class == courier.service_class);
    return null;
  },
};

const actions = {
  setLoadingAction({ commit }, loading) {
    commit('setLoading', loading);
  },
  async init({ commit, dispatch }) {
    commit('setLoading', true);
    await dispatch('getCouriers');
    await dispatch('getCourierMeta');
    commit('setLoading', false);
    commit('setInitialized', true);
  },
  async createCourier({ commit }, { cls, data }) {
    const result = await api.createCourier(cls, data);
    if (result.status === 'success') {
      commit('setCourier', result.data);
    }
    return result;
  },
  async resetCourier({ commit }, instance) {
    const result = await api.resetCourier(instance.id);
    if (result.status === 'success') {
      commit('setCourier', result.data);
      return result;
    }
  },
  async updateCourier({ commit }, { instance, updates }) {
    const result = await api.updateCourier(instance.id, updates);
    if (result.status === 'success') {
      commit('setCourier', result.data);
      return result;
    }
  },
  async getCourierMeta({ commit }) {
    const couriers = await api.getCourierMeta();
    commit('setCourierMeta', couriers);
  },
  async getCouriers({ commit }) {
    const couriers = await api.getCouriers();
    commit('setCouriers', couriers);
  },
  async install({ commit }, url) {
    const result = await api.getInstallService(url);

    if (result.status === 'success') {
      commit('appendCourier', result.data);
      this.$buefy.toast.open({
        message: 'Successvol gekoppeld',
        type: 'is-success',
      });
    }

    return result;
  },
};

const mutations = {
  setCourier(state, courier) {
    const idx = state.all.findIndex(o => o.id === courier.id);
    if (idx >= 0) state.all.splice(idx, 1);
    state.all.push(courier);
  },
  appendCourier(state, courier) {
    state.all.push(courier);
  },
  setCourierMeta(state, meta) {
    state.meta = meta;
  },
  setCouriers(state, couriers) {
    state.all = couriers;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setInitialized(state, initialized) {
    state.initialized = initialized;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
