<template>
  <main class="form-page">
    <div class="section">
      <header class="container block" :class="{ 'is-max-desktop': currentStage != 0 }">
        <h1 class="title">Onderzoek aanvragen</h1>
      </header>
      <form id="ticket-form" class="is-relative" @submit.prevent="createTicket">
        <b-loading :active="isLoading || isSubmitting" :is-full-page="false"></b-loading>
        <section v-if="currentStage == 0" class="container block">
          <div class="content">
            <h2 class="title is-4">Stap 1: Controleer zending</h2>
            <p>Alleen complete zendingen kunnen worden onderzocht. Controleer hieronder of alle gegevens kloppen.</p>
          </div>
          <!-- TODO: refactor opportunity. This is mostly duplicated from views/Label.vue -->
          <div class="columns">
            <div class="column is-one-third-desktop">
              <h2 class="title is-5">Geschiedenis</h2>
              <ul class="timeline block">
                <li v-for="(activity, index) in history" :key="index" :class="activity.class">
                  <p>{{ activity.description }}</p>
                  <p v-if="activity.period" class="is-size-7 has-text-grey">
                    <template v-if="deliveryEstimate.start && deliveryEstimate.end">
                      <template
                        v-if="
                          new Date(deliveryEstimate.start).toDateString() ==
                          new Date(deliveryEstimate.end).toDateString()
                        "
                      >
                        {{ deliveryEstimate.start | humanizeTimestamp('day') }}
                        tussen
                        {{ deliveryEstimate.start | humanizeTimestamp('time') }}
                        en
                        {{ deliveryEstimate.end | humanizeTimestamp('time') }}
                      </template>
                      <template v-else>
                        tussen
                        {{ deliveryEstimate.start | humanizeTimestamp }}
                        en
                        {{ deliveryEstimate.end | humanizeTimestamp }}
                      </template>
                    </template>
                    <template v-else-if="deliveryEstimate.start">
                      {{ deliveryEstimate.start | humanizeTimestamp('day') }}
                      vanaf
                      {{ deliveryEstimate.start | humanizeTimestamp('time') }}
                    </template>
                    <template v-else-if="deliveryEstimate.end">
                      {{ deliveryEstimate.end | humanizeTimestamp('day') }}
                      voor
                      {{ deliveryEstimate.end | humanizeTimestamp('time') }}
                    </template>
                  </p>
                  <p v-else class="is-size-7 has-text-grey">
                    {{ activity.last_updated | humanizeTimestamp }}
                  </p>
                </li>
              </ul>
              <template v-if="label.invoice_items">
                <b-collapse animation="slide" aria-id="invoice_items_collapse" :open="false">
                  <template #trigger="props">
                    <a aria-controls="invoice_items_collapse" :aria-expanded="props.open" class="block">
                      <h2>
                        Prijs en facturatie
                        <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
                      </h2>
                    </a>
                  </template>
                  <div v-for="(price, idx) in label.invoice_items" :key="'invoice_item' + idx" class="block">
                    <v-with
                      v-slot="{ total }"
                      :total="price.parts.reduce((acc, value) => acc + parseFloat(value.unit_price), 0)"
                    >
                      <table class="table is-striped is-hoverable is-narrow is-fullwidth">
                        <thead>
                          <tr>
                            <th v-if="price.type === 'label_price'" colspan="2">Labelprijs</th>
                            <th v-else-if="price.type === 'surcharge'" colspan="2">Naheffing</th>
                            <th v-else-if="price.type === 'refund'" colspan="2">Terugbetaling</th>
                            <th v-else colspan="2">Onbekend factuurtype</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in price.parts" :key="'invoice_item' + idx + item.id">
                            <td>{{ item.description }}</td>
                            <td class="has-text-right">{{ item.unit_price | formatMoney }}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>Totaal</th>
                            <th class="has-text-right">{{ total | formatMoney }}</th>
                          </tr>
                        </tfoot>
                      </table>
                    </v-with>
                  </div>
                </b-collapse>
              </template>
            </div>
            <div class="column is-two-thirds-desktop">
              <div class="columns is-multiline">
                <div class="column is-full-tablet is-half-desktop">
                  <h2 class="title is-5">Ontvanger</h2>
                  <div class="properties">
                    <div class="property">
                      <div class="name">Naam</div>
                      <div class="value">{{ label.name }}</div>
                    </div>

                    <div v-if="label.company_name" class="property">
                      <div class="name">Bedrijf</div>
                      <div class="value">{{ label.company_name }}</div>
                    </div>

                    <div class="property">
                      <div class="name">Adres</div>
                      <div class="value">
                        <address>
                          {{ label.address_1 }} {{ label.housenumber }}<br />
                          <template v-if="label.address_2">{{ label.address_2 }}<br /></template>
                          {{ label.zipcode }} {{ label.city }}<br />
                          <country v-model="label.country" />
                        </address>
                      </div>
                    </div>

                    <div class="property">
                      <div class="name">E-mail</div>
                      <div v-if="label.email" class="value">
                        <a :href="`mailto:${label.email}`">{{ label.email }}</a>
                      </div>
                      <div v-else class="value">
                        <b-input v-model="fields.email" placeholder="Email ontvanger" required type="email"></b-input>
                      </div>
                    </div>

                    <div class="property">
                      <div class="name">Telefoonnummer</div>
                      <div v-if="label.phone" class="value">
                        {{ label.phone }}
                      </div>
                      <div v-else class="value">
                        <b-input
                          v-model="fields.phone"
                          class="value"
                          placeholder="Telefoonnummer ontvanger"
                          required
                          type="tel"
                        ></b-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-full-tablet is-half-desktop">
                  <h2 class="title is-5">Zending</h2>
                  <div class="properties">
                    <div class="property">
                      <p class="name">Vervoerder</p>
                      <p class="value">
                        <courier :item="label" :show-warnings="false" />
                      </p>
                    </div>

                    <div class="property">
                      <p class="name">Status</p>
                      <p class="value">
                        <status :item="label" :no-class="true"></status>
                      </p>
                    </div>

                    <!-- TODO: Find a better solution for these nested properties. They don't look right -->
                    <div
                      v-if="
                        label &&
                        label.shipment &&
                        label.shipment.courier_fields &&
                        Object.keys(label.shipment.courier_fields).length > 0 &&
                        courierMeta
                      "
                      class="property"
                    >
                      <p class="name">Gekozen verzendopties</p>
                      <div v-if="courierFields.length" class="value content">
                        <ul>
                          <li v-for="(field, key) in courierFields" :key="key">
                            <div v-if="!!field">
                              {{ field.props.label }}
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div v-else class="value">Geen verzendopties gekozen</div>
                    </div>

                    <template v-if="label.shipment">
                      <template v-if="label.shipment?.packages.length > 1">
                        <h2 class="title is-5">Pakketen</h2>

                        <ul
                          style="display: flex; flex-direction: column; gap: 8px; max-height: 330px; overflow-y: auto"
                        >
                          <li v-for="(packageItem, index) in label.shipment.packages" :key="index" class="card">
                            <div class="card-content p-4 is-flex is-justify-content-space-between">
                              <a v-if="packageItem.tracking_url" :href="packageItem.tracking_url">
                                <font-awesome-icon icon="barcode" /> {{ packageItem.tracking_number }}
                              </a>

                              <p v-else><font-awesome-icon icon="barcode" /> {{ packageItem.tracking_number }}</p>

                              <p style="display: flex; gap: 8px; align-items: center">
                                <font-awesome-icon icon="scale-unbalanced" />{{ packageItem.weight }} gram
                              </p>
                            </div>
                          </li>
                        </ul>
                      </template>

                      <template v-else>
                        <h2 class="title is-5">Pakket</h2>
                        <div class="properties">
                          <div class="property">
                            <div class="name" style="display: flex; align-items: center; gap: 4px">
                              <font-awesome-icon icon="barcode" />Track & Trace
                            </div>
                            <div class="value">
                              <a
                                v-if="label.shipment.packages[0].tracking_url"
                                :href="label.shipment.packages[0].tracking_url"
                              >
                                {{ label.shipment.packages[0].tracking_number }}
                              </a>
                              <p v-else>
                                {{ label.shipment.packages[0].tracking_number }}
                              </p>
                            </div>
                          </div>
                          <div class="property">
                            <div class="name" style="display: flex; align-items: center; gap: 4px">
                              <font-awesome-icon icon="scale-unbalanced" />
                              Gewicht
                            </div>
                            <div class="value">{{ label.shipment.packages[0].weight }} gram</div>
                          </div>
                        </div>
                      </template>
                    </template>

                    <div v-if="label.shipment_weight" class="property">
                      <p class="name" style="display: flex; align-items: center; gap: 4px">
                        <font-awesome-icon icon="scale-unbalanced" />
                        Gewicht
                      </p>
                      <p class="value">{{ label.shipment_weight }} gram</p>
                    </div>

                    <div v-if="label.shipment_packages" class="property">
                      <p class="name">Aantal pakketten</p>
                      <p class="value">{{ label.shipment_packages }}</p>
                    </div>
                  </div>

                  <h2 class="title is-4">Order</h2>
                  <div class="properties">
                    <div v-if="label.order_number" class="property">
                      <div class="name">Ordernummer</div>
                      <div class="value">{{ label.order_number }}</div>
                    </div>

                    <div v-if="label.order_date" class="property">
                      <div class="name">Orderdatum</div>
                      <div class="value">{{ label.order_date | formatTimestamp }}</div>
                    </div>

                    <div v-if="label.order_status" class="property">
                      <div class="name">Shop Status</div>
                      <div class="value">{{ label.order_status }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="label.order_items && label.order_items.length > 0">
            <h2 class="title is-4">Orderregels</h2>
            <b-table :data="label.order_items" hoverable mobile-cards striped>
              <b-table-column v-slot="props" field="name" label="Naam">
                {{ props.row.name }}
              </b-table-column>
              <b-table-column v-slot="props" field="description" label="Omschrijving">
                {{ props.row.description }}
              </b-table-column>
              <b-table-column v-slot="props" field="quantity" label="Aantal" numeric>
                {{ props.row.quantity }}
              </b-table-column>
              <b-table-column v-slot="props" field="unit_price_inc_btw" label="Prijs per product" numeric>
                {{ props.row.unit_price_inc_btw | formatMoney(label.currency || 'EUR') }}
              </b-table-column>
              <b-table-column v-slot="props" field="weight" label="Gewicht per product" numeric>
                {{ props.row.weight | humanizeUnit('gram') }}
              </b-table-column>
              <b-table-column v-slot="props" field="category" label="Categorie">
                {{ props.row.category }}
              </b-table-column>
              <b-table-column v-slot="props" field="sku" label="SKU-code" numeric>
                {{ props.row.sku }}
              </b-table-column>
              <b-table-column v-slot="props" field="hs_code" label="HS-code" numeric>
                {{ props.row.hs_code }}
              </b-table-column>
              <b-table-column v-slot="props" field="product_country" label="Land van herkomst">
                <country :value="props.row.product_country" />
              </b-table-column>
            </b-table>
          </div>
        </section>
        <section v-else-if="currentStage == 1" class="container is-max-desktop block">
          <div class="content">
            <h2 class="title is-4">Stap 2: Geef extra informatie</h2>
            <p>Om de zending te onderzoeken, hebben we nog extra gegevens nodig.</p>
          </div>
          <b-field label="Reden onderzoek">
            <b-select v-model="fields.subject" required>
              <option v-for="subject in subjects" :key="subject" :value="subject">{{ subject }}</option>
            </b-select>
          </b-field>

          <b-field label="Toelichting" message="Geef extra informatie die ons kan helpen bij het onderzoek">
            <b-input
              v-model="fields.message"
              placeholder="Bijvoorbeeld: de klant heeft niets ontvangen en volgens track en trace is de zending al een week in het sorteercentrum"
              required
              type="textarea"
            ></b-input>
          </b-field>

          <b-field
            label="Omschrijf de uiterlijke kenmerken van het pakket"
            message="Omschrijf de verpakking, kleur tape, vorm, en grootte van het pakket"
          >
            <b-input
              v-model="fields.external_properties"
              placeholder="Bijvoorbeeld: 20cm × 30cm × 50cm, wit en groene doos met transparante tape"
              required
              type="textarea"
            ></b-input>
          </b-field>

          <b-field
            label="Omschrijf de inhoud van het pakket"
            message="Geef aan wat de verzonder producten zijn, indien er geen orderregels bekend zijn."
          >
            <b-input
              v-model="fields.internal_properties"
              placeholder="Bijvoorbeeld: 1 doos boutjes, 1 doos moertjes, 2 stekkers, 2 snoeren"
              required
              type="textarea"
            ></b-input>
          </b-field>

          <b-field
            label="Inkoopwaarde pakket"
            message="Wij hebben de inkoopwaarde nodig om de mogelijke vergoeding te bepalen. Alleen de inkoopwaarde wordt vergoed."
          >
            <p class="control">
              <span class="button is-static">€</span>
            </p>
            <b-input v-model="fields.cost_price" expanded min="0" required step="0.01" type="number"></b-input>
          </b-field>

          <b-field
            label="Upload verkoopfactuur"
            message="Wij hebben de verkoopfactuur nodig om de zending en de inhoud van het pakket te controleren."
          >
            <b-upload v-model="files" drag-drop expanded multiple>
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="software-upload" size="is-large"> </b-icon>
                  </p>
                  <p>Sleep je bestand hier naartoe of klik om te uploaden.</p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <div class="tags">
            <b-tag
              v-for="(file, idx) in files"
              :key="file.name"
              aria-close-label="Verwijder bestand"
              closable
              @close="files.splice(idx, 1)"
            >
              {{ file.name }}
            </b-tag>
          </div>
        </section>
      </form>
    </div>
    <form-page-footer>
      <div class="container field is-grouped is-grouped-right" :class="{ 'is-max-desktop': currentStage != 0 }">
        <p class="control">
          <b-button v-if="currentStage == 0" @click="$router.backOrDefault(defaultReturnRoute)"> Annuleren </b-button>
          <b-button v-else @click="currentStage -= 1"> Terug </b-button>
        </p>
        <p class="control">
          <template>
            <b-button v-if="currentStage == 0" type="is-primary" @click="goToNextStage"> Doorgaan </b-button>
            <b-button
              v-else-if="currentStage == 1"
              :disabled="isSubmitting || isUvDeskEnabled"
              form="ticket-form"
              :loading="isSubmitting"
              native-type="submit"
              type="is-primary"
            >
              Onderzoek aanvragen
            </b-button>
          </template>
        </p>
      </div>
    </form-page-footer>
  </main>
</template>

<script>
  import Country from '@/components/properties/Country';
  import Courier from '@/components/properties/Courier';
  import Status from '@/components/properties/Status';
  import FormPageFooter from '@/components/form/FormPageFooter';
  import { getBase64 } from '@/utils/functions';
  import { CONFIG_UVDESK_ENABLED } from '@/config';

  export default {
    props: {},
    components: { Country, FormPageFooter, Status, Courier },
    data() {
      const subjects = [
        'Bezorgd maar niet ontvangen',
        'Zending staat vast in sorteercentrum',
        'Wanneer wordt het pakket bezorgd?',
        'Bewijs van levering',
        'Zending onterecht terug bij afzender',
        'Anders',
      ]; //what more?
      return {
        isOpen: 0,
        defaultReturnRoute: { name: 'dashboard', params: { activeTabName: 'labels' } }, // different default?
        currentStage: 0,
        labelId: this.$route.params.labelId,
        label: {},
        isLoading: false,
        isSubmitting: false,
        files: [],
        fields: {
          email: '',
          phone: '',
          message: '',
          cost_price: 0,
          internal_properties: '',
          external_properties: '',
          subject: subjects[0],
        },
        subjects: subjects,
      };
    },
    computed: {
      courierMeta() {
        const courier = this.$store.getters['courier/courierById'](this.label.courier_id || this.label.service_id);
        return this.$store.state.courier.meta.find(c => c.class == courier.service_class);
      },

      isUvDeskEnabled() {
        return CONFIG_UVDESK_ENABLED;
      },

      isPhoneFilled() {
        if (this.label.phone)
          // phone is already filled in. No need to check fields
          return true;
        else return this.fields.phone !== '';
      },
      isEmailFilled() {
        if (this.label.email)
          // email is already filled in. No need to check fields
          return true;
        else return this.fields.email !== '';
      },
      courierFields() {
        let fields = [];
        if (this.courierMeta) {
          for (const fieldName in this.label.shipment.courier_fields) {
            const value = this.label.shipment.courier_fields[fieldName];
            if (value && value != 0) {
              fields.push({
                id: fieldName,
                props: this.courierMeta.order_fields.find(f => f.name == fieldName),
                value: value,
              });
            }
          }
        }
        return fields;
      },
      deliveryEstimate() {
        const exclude = [
          'delivered',
          'picked_up_servicepoint',
          'delivered_servicepoint',
          'error',
          'incomplete',
          'blocked',
          'delivery_failure',
        ];
        // const exclude = [];
        if (
          (this.label.tracking_data?.estimated_delivery_start || this.label.tracking_data?.estimated_delivery_end) &&
          !exclude.includes(this.label.status)
        )
          return {
            start: this.label.tracking_data?.estimated_delivery_start,
            end: this.label.tracking_data?.estimated_delivery_end,
          };
        return null;
      },
      history() {
        let history = this.label?.tracking_data?.status_breadcrumbs?.slice() || [];
        history.push({
          description: 'Laatste update',
          last_updated: this.label.updated_at,
          class: 'is-future',
        });
        history.push({
          description: 'Label gemaakt in Innosend',
          last_updated: this.label.created_at,
        });
        if (this.label.order_date)
          history.push({
            description: 'Bestelling geplaatst',
            last_updated: this.label.order_date,
          });
        if (this.deliveryEstimate)
          history.push({
            description: 'Bezorgmoment',
            last_updated: this.deliveryEstimate.end || this.deliveryEstimate.start,
            period: this.deliveryEstimate,
            class: 'is-future',
          });
        history.sort((a, b) => new Date(b.last_updated) - new Date(a.last_updated));
        return history;
      },
    },
    methods: {
      goToNextStage() {
        if (this.currentStage === 0) {
          //kinda unnecessary, as this method will only be used at 1 place. But you'll never know.
          if (!this.isPhoneFilled && !this.isEmailFilled) this.openToast('Vul email en telefoonnummer in.');
          else if (!this.isEmailFilled) this.openToast('Vul email in.');
          else this.currentStage += 1;
        }
      },
      openToast(message, type = 'is-danger') {
        this.$buefy.toast.open({
          message: message,
          type: type,
          duration: 5000,
        });
      },
      async createTicket() {
        this.isSubmitting = true;
        // TODO: Remove this as soon as backend supports multiple files
        if (this.files.length === 0) {
          this.openToast('Je moet een verkoopfactuur uploaden');
          this.isSubmitting = false;
          return;
        }

        if (this.files.length > 1) {
          this.openToast('Er kan maar 1 bestand op worden gestuurd');
          this.isSubmitting = false;
          return;
        }
        // Other validation is done through required and type attrs on the form fields
        const data = {
          label_id: this.label.id,
          attachments: await this.parseFiles(this.files),
          ...this.fields,
        };
        try {
          const result = await this.$store.dispatch('ticket/createTicket', data);
          if (result.status === 'success') return this.$router.push({ name: 'ticket-list' });
        } finally {
          this.isSubmitting = false;
        }
      },
      async parseFiles(files) {
        let parsed = [];
        if (files) {
          for (const file of files) {
            parsed.push({
              filename: file.name,
              base_64: await getBase64(file),
            });
          }
        }
        return parsed;
      },
    },
    async created() {
      this.isLoading = true;
      try {
        this.label = await this.$store.dispatch('label/getLabel', { labelId: this.labelId });
      } finally {
        this.isLoading = false;
      }
    },
    beforeRouteUpdate(to, from, next) {
      this.labelId = to.params.labelId;
      next();
    },
    filters: {
      formatValue(value) {
        if (value === true) return 'Ja';
        else if (value === false) return 'Nee';
        else if (!value) return '-';
        return value;
      },
      humanizeUnit(value, unit) {
        return Intl.NumberFormat('nl-NL', { style: 'unit', unit }).format(value);
      },
    },
  };
</script>
