const injectedConfig = window?.config;

export const CONFIG_BILLING_ENABLED = true;
export const CONFIG_INNOSCAN_ENABLED = true;
export const CONFIG_UVDESK_ENABLED = true;
export const CONFIG_RETURN_ENABLED = true;
export const CONFIG_SHOULD_CHECK_FOR_PAYMENT = true;
export const CONFIG_TRACKING_ENABLED = true;

/**
 * @typedef {'production' | 'staging' | 'test' | 'development'} EnvType
 */

/** @type {EnvType} */
export const CONFIG_ENV = injectedConfig?.VUE_APP_ENV || process.env.VUE_APP_ENV || 'development';
export const CONFIG_INTERCOM_APP_ID = injectedConfig?.VUE_APP_INTERCOM_APP_ID || process.env.VUE_APP_INTERCOM_APP_ID;

const getApiSuffix = () => {
  if (CONFIG_ENV === 'staging') return '-staging';
  if (CONFIG_ENV === 'test') return '-test';
  return '';
};

const API_SUFFIX = getApiSuffix();

export const BACKEND_PORT = process.env.VUE_APP_API_PORT || 5000;
export const RETURN_PORT = process.env.VUE_APP_RETURN_PORT || 3000;
export const TRACKING_PORT = process.env.VUE_APP_TRACKING_PORT || 3001;
export const UVDESK_PORT = process.env.VUE_APP_UVDESK_PORT || 8084;

const isDevelopment = CONFIG_ENV === 'development';

export const CONFIG_API_URL = isDevelopment
  ? `http://127.0.0.1:${BACKEND_PORT}/api/`
  : `https://api${API_SUFFIX}.innosend.eu/api/`;

export const CONFIG_INNOSEND_RETURN_BASE_URL = isDevelopment
  ? `http://127.0.0.1:${RETURN_PORT}`
  : `https://retour${API_SUFFIX}.innosend.eu`;

export const CONFIG_INNOSEND_TRACKING_BASE_URL = isDevelopment
  ? `http://127.0.0.1:${TRACKING_PORT}`
  : `https://tracking${API_SUFFIX}.innosend.eu`;

export const CONFIG_PRINTER_CLIENT_URL = 'http://127.0.0.1:8097/';

export const CONFIG_PRINTER_SERVER_URL = isDevelopment
  ? 'wss://printserver.innosend.eu/labels'
  : `wss://printserver${API_SUFFIX}.innosend.eu/labels`;

export const CONFIG_UVDESK_BASE_URL = isDevelopment
  ? `http://127.0.0.1:${UVDESK_PORT}`
  : `https://uvdesk${API_SUFFIX}.innosend.eu`;

export const CONFIG_POLL_INTERVAL_IN_SECONDS = 20;
export const CONFIG_BARCODE_CHAR_TIMEOUT_IN_SECONDS = 50;

// innosend-dev innosend-prod innosend-staging innosend-test
const getRealm = () => {
  switch (CONFIG_ENV) {
    case 'prod':
      return 'innosend-prod';
    case 'staging':
      return 'innosend-staging';
    case 'test':
      return 'innosend-test';
    default:
      return 'innosend-dev';
  }
};

export const CONFIG_KEYCLOAK_REALM = getRealm();
