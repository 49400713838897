import { api } from '@/utils';

const state = {
  all: [],
  meta: [],
  loading: false,
};

const getters = {
  shopById: state => id => state.all.find(o => o.id === id),
  metaById: (state, getters) => id => {
    let shop = getters.shopById(id);
    if (shop) return state.meta.find(c => c.class == shop.service_class);
    return null;
  },
};

const actions = {
  async init({ dispatch }) {
    await dispatch('getShops');
    await dispatch('getShopMeta');
  },

  setLoadingAction({ commit }, loading) {
    commit('setLoading', loading);
  },

  async createShop({ commit }, { cls, data }) {
    const result = await api.createShop(cls, data);
    if (result.status === 'success') {
      commit('setShop', result.data);
      return result;
    }
  },
  async resetShop({ commit }, instance) {
    const result = await api.resetShop(instance.id);
    if (result.status === 'success') {
      commit('setShop', result.data);
      return result;
    }
  },
  async updateShop({ commit }, { instance, updates }) {
    const result = await api.updateShop(instance.id, updates);
    if (result.status === 'success') {
      commit('setShop', result.data);
      return result;
    }
  },
  async getShopMeta({ commit }) {
    const shops = await api.getShopMeta();
    commit('setShopMeta', shops);
  },
  async getShops({ commit }) {
    commit('setLoading', true);
    const shops = await api.getShops();
    commit('setShops', shops);
  },
  async install({ commit }, url) {
    const result = await api.getInstallService(url);

    if (result.status === 'success') {
      commit('appendShop', result.data);
    }

    return result;
  },
};

const mutations = {
  setShop(state, shop) {
    const idx = state.all.findIndex(o => o.id === shop.id);
    if (idx >= 0) state.all.splice(idx, 1);
    state.all.push(shop);
  },
  setShopMeta(state, meta) {
    state.meta = meta;
  },
  setShops(state, shops) {
    state.all = shops;
    state.loading = false;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  appendShop(state, shop) {
    state.all.push(shop);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
