import hash from 'object-hash';
import store from '@/store';

export class ApiError extends Error {
  name = 'ApiError';

  constructor(message, url, response) {
    super(message);
    this.url = url;
    this.response = response;
    this.apiMessage = response?.message;
  }
}

export class UnauthorizedError extends ApiError {}

export class AbortPrintException extends Error {}

export class GenericError extends Error {
  name = 'GenericError';

  constructor(title, message) {
    super(message);
    this.title = title;
  }
}

export function getErrorHash(error) {
  if (error.type === 'ApiError' && error.response?.message_code)
    return hash({
      message: error.message,
      status: error.response.status,
      messageCode: error.response.message_code,
    });
  else if (error.type === 'ApiError')
    return hash({
      message: error.message,
      response: error.response,
    });
  return hash(error, { excludeKeys: key => key === 'error' });
}

export function reportError(error) {
  let errorData = {
    error,
    type: error.name,
    message: error.message,
  };
  if (error instanceof ApiError) {
    errorData.url = error.url;
    errorData.response = error.response;
  }
  store.commit('app/createError', errorData);

  if (process.env.NODE_ENV !== 'production') console.error(error);
}
